export const ItemStep = [
  {
    step: 1,
    name: "Registration",
  },
  {
    step: 2,
    name: "Create Profile",
  },
  {
    step: 3,
    name: "Change Profile Photo",
  },
  {
    step: 4,
    name: "Find Friends",
  },
];
