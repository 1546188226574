import React from "react";
import Member from "./MemberTab/Member";

const MembersTab = () => {
  return (
    <>
      <div>
        <Member />
      </div>
    </>
  );
};

export default MembersTab;
